<template>
    <div class="" style="width: 100%; height: 100%">
        <el-table
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '13px',
                'font-weight': '600',
            }"
        >
            <el-table-column width="25"> </el-table-column>
            <el-table-column label="日期" width="100">
                <template slot-scope="scope">
                    {{
                        scope.row.accountTime
                            ? scope.row.accountTime.slice(0, 10)
                            : '- -'
                    }}
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="结算价" width="80">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.settleAmount || 0 }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="应收金额" width="80">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.receivableAmount || 0 }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="实收金额" width="80">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.actualAmount || 0 }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="累计实收金额" width="100">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.collectionAmount || 0 }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="回款进度" width="80">
                <template slot-scope="scope">
                    <div>
                        {{
                            parseInt(
                                (scope.row.collectionAmount /
                                    scope.row.receivableAmount) *
                                    100
                            ) + '%'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="业绩" width="80">
                <template slot-scope="scope">
                    <div>
                        {{
                           scope.row.performanceAmount || 0
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="状态" width="80">
                <template slot-scope="scope">
                    <div
                        :style="{
                            color:
                                scope.row.status == 1
                                    ? '#D0021B'
                                    : scope.row.status == 2
                                    ? '#2370eb'
                                    : '#F5A623',
                        }"
                    >
                        {{ $cost.performanceStatus(scope.row.status) }}
                    </div>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>

<script>
export default {
    name: '',
    props: {},
    data() {
        return {
            tableData: [],
            currentPage: 1,
            pagesize: 10,
            total: 0,
        };
    },
    components: {},
    created() {},
    methods: {
        getData(data) {
            this.tableData = [];
            this.tableData = [data];
        },
    },
};
</script>

<style lang="scss" scoped>
.el-table {
    font-size: 13px !important;
}
</style>
