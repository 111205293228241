<template>
    <div class="details">
        <div
            class="title_box"
            :style="{
                background:
                    'url(' + require('@/assets/bg2.png') + ') no-repeat',
                backgroundSize: 'cover',
            }"
        >
            <div class="name_operation">
                <p>
                    <span>
                        <i class="iconfont icon-wodekehu"></i>
                        明细详情
                    </span>
                </p>
                <p>
                    <el-button
                        @click="onClose()"
                        size="mini"
                        type="primary"
                        icon="el-icon-arrow-left"
                        style="
                            background: #fff;
                            color: #2370eb;
                            border-color: #2370eb;
                        "
                        >返回列表</el-button
                    >
                </p>
            </div>
            <div class="base_info_top">
                <div>
                    <p>
                        <span>结算部门</span>
                        <span>{{ data.departmentName || '- -' }}</span>
                    </p>
                    <p>
                        <span>下单人</span>
                        <span>{{
                            contractTargetStaff.adminName || '- -'
                        }}</span>
                    </p>
                    <p>
                        <span>所属人</span>
                        <span>{{
                            contractTargetStaff.ownerAdminName || '- -'
                        }}</span>
                    </p>
                    <p>
                        <span>合作人</span>
                        <span>
                        {{
                            contractTargetStaff.collaboratorAdminName || '- -'
                        }}</span>
                    </p>
                    <p>
                        <span>协作人</span>
                        <span>{{
                            contractTargetStaff.cooperationAdminName || '- -'
                        }}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="pay_info">
            <div>
                <div>
                    <p>
                        <span>结算编号</span>
                        <span>{{ data.performanceNo || '- -' }}</span>
                    </p>
                    <p>
                        <span>客户名称</span>
                        <span>{{ data.companyName || '- -' }}</span>
                    </p>
                </div>
                <div>
                    <p>
                        <span>交易时间</span>
                        <span>{{ data.accountTime || '- -' }}</span>
                    </p>
                    <p>
                        <span>合同编号</span>
                        <span>{{ data.contractNo || '- -' }}</span>
                    </p>
                </div>
                <div>
                    <p>
                        <span>回款金额</span>
                        <span>{{ data.collectionAmount || 0 }}</span>
                    </p>
                    <p>
                        <span>合同金额</span>
                        <span>{{ data.contractAmount || 0 }}</span>
                    </p>
                </div>
            </div>
            <div>
                <img src="@/img/salecost.png" alt="" />
                <div>
                    <p>月度销售费用</p>
                    <span>{{ expenseAmount || 0 }}</span>
                </div>
            </div>
        </div>
        <div class="pay_info2">
            <div class="name_operation">
                <p>
                    <span>
                        <i
                            class="iconfont icon-pay-order"
                            style="color: #ffbd27"
                        ></i>
                        产品信息
                    </span>
                </p>
                <p></p>
            </div>
            <div class="base_info">
                <div>
                    <p>
                        <span>产品名称</span>
                        <span>{{ returnProductName(data) || '- -' }}</span>
                    </p>
                    <p>
                        <span>用户数</span>
                        <span>{{
                            data.contractTargetBO &&
                            data.contractTargetBO.number
                                ? data.contractTargetBO.number
                                : '- -' || '- -'
                        }}</span>
                    </p>
                    <p>
                        <span>应收金额</span>
                        <span>{{
                            data.contractTargetBO
                                ? data.contractTargetBO.receivableAmount
                                : 0 || 0
                        }}</span>
                    </p>
                    <p>
                        <span>销售费用</span>
                        <span
                            class="cricle_box"
                            :style="{
                                borderColor: data.businessDeclareId
                                    ? '#2370eb'
                                    : '#F5A623',
                                color: data.businessDeclareId
                                    ? '#2370eb !important'
                                    : '#FFA802 !important',
                                background: data.businessDeclareId
                                    ? 'rgba(35, 112, 235, 0.09)'
                                    : 'rgba(255,168,2,0.14)',
                            }"
                            >{{
                                data.businessDeclareId ? '已申请' : '未申请'
                            }}</span
                        >
                    </p>
                    <p>
                        <span>系数</span>
                        <span
                            v-if="
                                data.businessDeclareBO &&
                                data.businessDeclareBO.declareType == 1
                            "
                            >{{
                                data.businessDeclareBO
                                    ? data.businessDeclareBO.ratio + '%'
                                    : '- -' || '- -'
                            }}</span
                        >
                        <span
                            v-if="
                                data.businessDeclareBO &&
                                data.businessDeclareBO.declareType == 2
                            "
                            >100%</span
                        >
                    </p>
                </div>
                <div>
                    <p>
                        <span>购买类型</span>
                        <span>{{ returnType(data) || '- -' }}</span>
                    </p>
                    <p>
                        <span>年限</span>
                        <span>{{
                            data.contractTargetBO
                                ? data.contractTargetBO.term
                                : '- -' || '- -'
                        }}</span>
                    </p>
                    <p>
                        <span>实收金额</span>
                        <span>{{ data.actualAmount || 0 }}</span>
                    </p>
                    <p style="position: relative">
                        <span></span>
                        <span class="butongguo">
                            <em
                                style="margin-right: 15px; font-style: normal"
                                >{{ returnDeclare(data) || '- -' }}</em
                            >
                            <img
                                v-if="
                                    data.businessDeclareBO &&
                                    data.businessDeclareBO.special == 1
                                "
                                src="@/img/butongguo.png"
                                alt=""
                            />
                        </span>
                    </p>
                    <p>
                        <span></span>
                        <span></span>
                    </p>
                </div>
            </div>
        </div>
        <div class="other_box">
            <div class="tab_box">
                <span :class="active == 1 ? 'active' : ''" @click="changeTab(1)"
                    >历史业绩明细</span
                >
                <span :class="active == 2 ? 'active' : ''" @click="changeTab(2)"
                    >当前业绩待确认</span
                >
            </div>
            <div style="flex: 1">
                <History ref="history" v-show="active == 1"></History>
                <Achievement
                    ref="achievement"
                    v-show="active == 2"
                ></Achievement>
            </div>
        </div>
        <div class="bnt">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #333;
                    margin-left: auto;
                "
                @click="onClose"
                >取消</el-button
            >

            <el-button
                round
                v-if="
                    btnP.confirm &&
                    2 == data.status &&
                    contractTargetStaff.adminId == adminId
                "
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 30px;
                "
                size="mini"
                @click="onSubmit"
                >确认业绩</el-button
            >
        </div>
    </div>
</template>
<script>
import History from './components/history.vue';
import Achievement from './components/achievement.vue';
import {
    performanceGet,
    performanceUpdate,
    contractTargetStaffGet,
    performanceHistoryList,
} from '@/api/performance/performance.js';
import { getAdminSaleAmount } from '@/api/cost/declare.js';
export default {
    components: {
        History,
        Achievement,
    },
    data() {
        return {
            data: {},
            businessInfoList: [{}],
            active: 2,
            expenseAmount: 0,
            btnP: {},
            contractTargetStaff: {},
            adminId: sessionStorage.getItem('adminId'),
        };
    },
    created() {},
    mounted() {
        this.getData();
    },
    methods: {
        changeTab(i) {
            this.active = i;
            if (i == 1) {
                this.performanceHistoryList();
            }
            if (i == 2) {
                if (this.data.status <= 2) {
                    setTimeout(() => {
                        this.$refs.achievement.getData(this.data);
                    }, 0);
                } else {
                    setTimeout(() => {
                        this.$refs.achievement.getData();
                    }, 0);
                }
            }
        },
        getAdminSaleAmount() {
            let data = {
                param: {
                    id: sessionStorage.getItem('adminId'),
                },
            };
            getAdminSaleAmount(data).then((res) => {
                if (res.code == 200) {
                    console.log(res);
                    this.expenseAmount = res.data.expenseAmount;
                }
            });
        },
        performanceHistoryList() {
            let data = {
                param: {
                    orderId: this.data.orderId,
                    adminId: sessionStorage.getItem('adminId'),
                },
            };
            performanceHistoryList(data).then((res) => {
                if (res.code == 200) {
                    this.$refs.history.getData(res.data.list);
                }
            });
        },
        contractTargetStaffGet() {
            let data = {
                param: {
                    id: this.data.contractTargetId,
                },
            };
            contractTargetStaffGet(data).then((res) => {
                if (res.code == 200) {
                    console.log(res);
                    this.contractTargetStaff = res.data;
                }
            });
        },
        getData() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = btn;

            let obj = {
                param: {
                    id: this.$route.query.id,
                },
            };
            performanceGet(obj).then((res) => {
                this.data = res.data;
                console.log(res.data);
                this.changeTab(this.active);
                this.getAdminSaleAmount();
                this.contractTargetStaffGet();
            });
        },
        onClose() {
            this.$router.push({
                path: '/myachievementlistdetails',
            });
        },
        returnName(data) {
            var str = '';
            data.departmentAdminDOList
                ? data.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },
        onSubmit() {
            let data = {
                param: {
                    id: this.data.id,
                    status: 3,
                },
            };
            performanceUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
        returnProductName(data) {
            let text = '- -';
            if (data.contractTargetBO) {
                data.contractTargetBO.productAttributeRelationBOList.forEach(
                    (item) => {
                        text = item.productName;
                    }
                );
            }
            return text;
        },
        returnContractTarget(data) {
            let text = '';
            if (data.contractTargetBO) {
                if (this.returnProductName(data) == '域名') {
                    let domainType =
                        data.contractTargetBO.areaType == 1
                            ? '中国域名'
                            : data.contractTargetBO.areaType == 2
                            ? '国际域名'
                            : '其他';
                    text =
                        '用户数：' +
                        data.contractTargetBO.number +
                        ';域名类型：' +
                        domainType;
                } else {
                    text =
                        '用户数：' +
                        data.contractTargetBO.number +
                        ';年限：' +
                        data.contractTargetBO.term;
                }
            }
            return text;
        },
        returnText(type, orderType = '') {
            let text = '';
            if (this.data.contractTargetId) {
                this.data.contractTargetBO.productAttributeRelationBOList.forEach(
                    (item) => {
                        if (item.attributeColumnName == type) {
                            item.productAttributeValueBOList.forEach((itm) => {
                                if (orderType) {
                                    if (
                                        itm.attributeValue ==
                                        this.data.contractTargetBO[orderType]
                                    ) {
                                        text = itm.attributeValueName;
                                    }
                                } else {
                                    if (
                                        itm.attributeValue ==
                                        this.data.contractTargetBO[type]
                                    ) {
                                        text = itm.attributeValueName;
                                    }
                                }
                            });
                        }
                    }
                );
            }
            return text;
        },
        returnDeclare(data) {
            let text = '';
            if (!data.businessDeclareId) {
                return text;
            }
            if (data.businessDeclareBO) {
                let declareType =
                    data.businessDeclareBO.declareType == 1
                        ? '订单销售费用'
                        : '月度报销费用';
                text =
                    declareType + ':' + data.businessDeclareBO.amount + '(￥)';
            }

            return text;
        },
        returnType(data) {
            let a = '- -';
            console.log(data.contractTargetBO);
            if (data.contractTargetBO) {
                data.contractTargetBO.productAttributeRelationBOList.forEach(
                    (item) => {
                        if (item.attributeColumnName == 'productType') {
                            item.productAttributeValueBOList &&
                            item.productAttributeValueBOList.length > 0
                                ? item.productAttributeValueBOList.forEach(
                                      (itm) => {
                                          if (
                                              data.contractTargetBO
                                                  .productType ==
                                              itm.attributeValue
                                          ) {
                                              a = itm.attributeValueName;
                                          }
                                      }
                                  )
                                : '- -';
                        }
                    }
                );
            }

            return a;
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.butongguo {
    position: absolute;
    left: 66px;
    bottom: -3px;
    width: 200px;
    display: flex;
    align-items: center;
    em {
        display: inline-block;
    }
    img {
        display: inline-block;
    }
}
.details {
    width: 100%;

    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 55px;
    box-sizing: border-box;
    overflow-y: auto;
    .title_box {
        padding: 16px 20px;
        background: #fff;
    }
    .base_info {
        font-size: 14px;

        > div {
            display: flex;
            margin-top: 16px;
            padding-left: 35px;

            > p {
                flex: 1;
                span:nth-child(1) {
                    color: #666666;
                    display: inline-block;
                    width: 65px;
                }
                span:nth-child(2) {
                    color: #333333;
                    font-weight: 600;
                    margin-right: 10px;
                }
            }
        }
    }
    .base_info_top {
        font-size: 14px;

        > div {
            display: flex;
            margin-top: 16px;
            padding-left: 35px;

            > p {
                span:nth-child(1) {
                    color: #666666;
                    display: inline-block;
                    width: 65px;
                }
                span:nth-child(2) {
                    color: #333333;
                    font-weight: 600;
                    margin-right: 10px;
                    display: inline-block;
                    width: 153px;
                }
            }
        }
    }
    .cricle_box {
        border-radius: 10px;
        border: 1px solid;
        padding: 0px 13px;

        font-weight: normal;
        font-size: 14px;
    }
    .other_box {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-top: 12px;
        .tab_box {
            height: 52px;
            line-height: 52px;
            border-bottom: 1px solid #eeeeee;
            background-color: #fff;
            padding-left: 36px;

            span + span {
                margin-left: 48px;
            }
            span {
                position: relative;
                color: #333;
                font-size: 14px;
                font-weight: 600;
                display: inline-block;
                cursor: pointer;
            }
            .active {
                color: #2370eb;
            }
            .active:after {
                content: '';
                position: absolute;
                bottom: 0px;
                left: 50%;
                transform: translateX(-50%);
                width: 16px;
                height: 2px;
                background: #2370eb;
                border-radius: 1px 1px 0px 0px;
            }
        }
    }
    .pay_info2 {
        background-color: #fff;
        margin-top: 12px;
        border-radius: 4px;
        padding: 16px 20px;
    }
    .pay_info {
        margin-top: 12px;
        border-radius: 4px;
        font-size: 14px;
        display: flex;
        > div:nth-child(1) {
            margin-right: 12px;
            flex: 1;
            background-color: #fff;
            padding: 16px 0 16px 58px;
            > div {
                display: flex;
                p {
                    flex: 1;
                }
            }
            div + div {
                margin-top: 16px;
            }
            span:nth-child(1) {
                color: #666666;
                display: inline-block;
                width: 65px;
            }
            span:nth-child(2) {
                color: #333333;
                font-weight: 600;
                margin-right: 10px;
            }
        }
        > div:nth-child(2) {
            width: 300px;
            display: flex;
            align-items: center;
            background-color: #fff;
            justify-content: center;
            img {
                margin-right: 18px;
                margin-left: -15px;
            }
            div {
                font-weight: 600;
                font-size: 16px;
                span {
                    font-size: 26px;
                    display: block;
                    margin-top: 10px;
                }
            }
        }
    }
    .bnt {
        background: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 14px 0;
        border-top: 1px solid #e5e5e5;
        text-align: center;
        z-index: 9;
        button {
            padding: 5px 30px;
        }
    }
    .name_operation {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;

        p {
            i {
                color: #2370eb;
                margin-right: 6px;
                font-weight: normal;
            }
            span {
                font-size: 14px;
                font-weight: 600;
            }
        }
        .el-button--primary.is-disabled {
            background-color: #fff;
            border-color: #ebeef5;
            color: #c0c4cc;
        }
        .el-button--primary {
            background-color: #2370eb;
        }
    }
}
.address {
    position: absolute;
    left: 67px;
    top: 1px;
}
</style>
